import { appConfig } from "~/utils/config";
import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters({
            "productData": "productView/getProduct",
            "metaTags": 'dynamicPage/metaTags',
        }),

        descriptionValue() {
            let description = this.metaTags.find(el => el.name === 'description');
            return (description?.content ?? "");
        },
        productHasSizes() {
            return Boolean(this.productData?.sizes?.filter(size => size.hasOwnProperty('isAvailable') && size.isAvailable).length);
        },
        imagesFullListUrls() {
            if (this.productData?.imagesFullList && this.productData?.imagesFullList.length) {
                return this.productData?.imagesFullList.map(imageItem => {
                    const imageSizes = ImageHelper.getMediaStructure(
                        imageItem.id,
                        "jpg",
                        imageItem.title,
                        imageItem.alt,
                        ImageSizes.catalogMain,
                        60
                    );
                    imageItem.simpleUrl = imageSizes?.src?.desktop?.["1x"];
                    return imageItem;
                });
            }
            return [];
        },
        productStructuredData() {
            return {
                type: "application/ld+json",
                json: {
                    "@context": "https://schema.org/",
                    "@type": "Product",
                    "image": this.getProductHeroImagesList(),
                    "name": `${ this.productData?.displayName }`,
                    "sku": `${ this.productData?.article }`,
                    "seller": {"@type": "Organization", "name": "adidas"},
                    "color": `${ this.productData?.color }`,
                    "description": `${ this.descriptionValue }`,
                    "brand": {
                        "@type": "Brand",
                        "name": `adidas ${ this?.productData?.division || "" }`
                    },
                    "offers": {
                        "@type": "Offer",
                        "url": `${ this.productData?.url?.absolute }`,
                        "itemCondition": "https://schema.org/NewCondition",
                        "availability": this.productHasSizes ? "https://schema.org/InStock" : "https://schema.org/OutOfStock",
                        "price": `${ this.productData?.price?.sale }`,
                        "priceCurrency": `${ appConfig?.mainInfo?.currency }`,
                        "shippingDetails": {
                            "@type": "DefinedRegion",
                            "addressCountry": "UA",
                            "businessDays": {
                                "@type": "OpeningHoursSpecification",
                                "dayOfWeek": [
                                    "https://schema.org/Monday",
                                    "https://schema.org/Tuesday",
                                    "https://schema.org/Wednesday",
                                    "https://schema.org/Thursday",
                                    "https://schema.org/Friday",
                                    "https://schema.org/Saturday",
                                    "https://schema.org/Sunday"
                                ],
                                "opens": `${ appConfig?.mainInfo?.openUTCTime?.from }`,
                                "closes": `${ appConfig?.mainInfo?.openUTCTime?.to }`,
                            }
                        }
                    },
                }
            }
        }
    },

    methods: {
        getProductHeroImagesList() {
            return this.imagesFullListUrls && this.imagesFullListUrls.length ?
                this.imagesFullListUrls.map(item => item.simpleUrl) :
                [];
        }
    },
};
